"use client";

import { useTranslations } from "next-intl";

import { Button } from "@/components/Button";
import { useSideCartContext } from "@/providers/SideCartProvider";

export const EmptyCart = () => {
  const t = useTranslations();
  const { setIsOpen } = useSideCartContext();

  return (
    <>
      <hr />
      <p className="py-6 text-center">{t("checkout.yourCartIsEmpty")}</p>
      <Button color="gold" onClick={() => setIsOpen(false)}>
        {t("common.continueShopping")}
      </Button>
    </>
  );
};
