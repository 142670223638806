"use client";

import { Bars3Icon } from "@heroicons/react/24/outline";
import { ChevronDownIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { useWindowSize } from "@uidotdev/usehooks";
import { usePathname } from "next/navigation";
import { useTranslations } from "next-intl";
import { Fragment, useEffect, useState } from "react";

import { type MenuItem } from "@projectluna/codegen/schema";
import { mapEdgesToItems } from "@projectluna/lib/graphql/utils";

import { Drawer } from "@/components/Drawer";
import { menuToNavItems } from "@/components/Header/helpers";
import { Icon } from "@/components/Icon";
import { Link } from "@/components/Link";
import { useOnRouteChange } from "@/hooks/useOnRouteChange";
import { useLocalizedPaths } from "@/lib/paths/client";
import { type WithHTMLProp } from "@/lib/types";

import { type Navigation, type NavItem } from "../types";

import { cn, screenSizes } from "@/styles/lib";

const renderRecursiveMenu = ({
  items,
  isExpanded,
  setCollapsed,
  setExpanded,
  currentPathname,
  level = 1,
}: {
  currentPathname: string;
  isExpanded: (key: string) => boolean;
  items: NavItem[];
  level?: number;
  setCollapsed: (key: string) => void;
  setExpanded: (key: string) => void;
}) =>
  items.map(({ text, linkProps, items }, i) => {
    const url = linkProps.href;
    const key = `${url}-${text}-${i}-${level}`;
    const isSameHref = currentPathname === url;

    if (!items?.length) {
      return (
        <li className="p-3" key={key}>
          {isSameHref || !url ? (
            <p>{text}</p>
          ) : (
            <Link {...linkProps} key={key}>
              <p>{text}</p>
            </Link>
          )}
        </li>
      );
    }

    return (
      <Fragment key={key}>
        <li>
          <div className="flex w-full items-center justify-between transition-colors hover:bg-black/10">
            {isSameHref || !url ? (
              <p className="w-[90%] p-3">{text}</p>
            ) : (
              <Link className="w-[90%] p-3" href={url}>
                <p>{text}</p>
              </Link>
            )}
            <ChevronDownIcon
              className={cn(
                "-my-3 h-[48px] cursor-pointer p-3 text-gold transition-transform",
                { "rotate-180": isExpanded(key) }
              )}
              onClick={evt => {
                evt.preventDefault();
                isExpanded(key) ? setCollapsed(key) : setExpanded(key);
              }}
            />
          </div>
          <div
            className={cn(
              "grid grid-rows-[0fr] transition-all [&>*]:overflow-hidden",
              { "grid-rows-[1fr]": isExpanded(key) }
            )}
          >
            <ul style={{ marginLeft: `${20}px` }}>
              {renderRecursiveMenu({
                items,
                isExpanded,
                setCollapsed,
                setExpanded,
                currentPathname,
                level: level + 1,
              })}
            </ul>
          </div>
        </li>
      </Fragment>
    );
  });

type MobileMenuProps = Navigation & WithHTMLProp<"className">;

export const MobileMenu = ({
  navigation,
  categories: categoriesEdges,
  className,
}: MobileMenuProps) => {
  const [expanded, _setExpanded] = useState<string[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const paths = useLocalizedPaths();
  const { width } = useWindowSize();
  const pathname = usePathname();
  const t = useTranslations();

  const categories = mapEdgesToItems(categoriesEdges).filter(
    ({ products }) => products?.totalCount ?? 0 > 0
  );
  const navItems = menuToNavItems({
    paths,
    menu: {
      ...navigation,
      items: [
        {
          name: t("product.categories"),
          children: categories.map(category => ({
            category,
          })),
        } as MenuItem,
        ...(navigation?.items ?? []),
      ],
    },
  });
  const isLgDown = width && width < screenSizes.lg;

  const isExpanded = (key: string) => expanded.includes(key);

  const setExpanded = (key: string) =>
    !isExpanded(key) && _setExpanded(s => [...s, key]);

  const setCollapsed = (key: string) =>
    _setExpanded(s => s.filter(k => k !== key));

  useEffect(() => {
    if (!isOpen && expanded.length) {
      _setExpanded([]);
    }
  }, [isOpen]);

  useEffect(() => {
    /**
     * Close sheet if is open and screen is bigger then sm.
     */
    if (isOpen && !isLgDown) {
      setIsOpen(false);
      _setExpanded([]);
    }
  }, [width]);

  useOnRouteChange(() => {
    if (isOpen) {
      setIsOpen(false);
      _setExpanded([]);
    }
  });

  return (
    <>
      <div className={cn("flex items-center", className)}>
        <Icon className="mr-[40px]" onClick={() => setIsOpen(true)}>
          <Bars3Icon />
        </Icon>
      </div>
      <Drawer isOpen={isOpen} setIsOpen={setIsOpen}>
        <div className="h-full bg-white p-6">
          <div className="flex justify-end">
            <Icon onClick={() => setIsOpen(false)}>
              <XMarkIcon />
            </Icon>
          </div>

          <div>
            <ul>
              {renderRecursiveMenu({
                items: navItems,
                isExpanded,
                setCollapsed,
                setExpanded,
                currentPathname: pathname,
              })}
            </ul>
          </div>
        </div>
      </Drawer>
    </>
  );
};
