"use client";

import { Link } from "@/components/Link";
import { useLocalizedPaths } from "@/lib/paths/client";
import { type WithHTMLProp } from "@/lib/types";

import { ReactComponent as LogoSign } from "@/assets/svg/logo_sign.svg";

import { cn } from "@/styles/lib";

export const Logo = ({ className }: WithHTMLProp<"className">) => {
  const paths = useLocalizedPaths();

  return (
    <Link
      className={cn("flex items-center", className)}
      href={paths.home.asPath()}
    >
      <LogoSign className="stroke-black stroke-[0.25px]" height={40} />
    </Link>
  );
};
