import type { PropsWithChildren } from "react";

import { type WithHTMLProp } from "@/lib/types";

import { cn } from "@/styles/lib";

export type SaleBadgeProps = PropsWithChildren &
  WithHTMLProp<"className"> & {
    backgroundColor?: "red" | "gold" | "orange";
  };

export const Badge = ({
  backgroundColor = "gold",

  children,
  className,
}: SaleBadgeProps) => (
  <mark
    className={cn(
      "absolute",
      "font-bold",
      "inline-block",
      "pointer-events-all",
      "px-2",
      "py-[3px]",
      "rounded-md",
      "text-sm",
      "text-white",
      "z-10",
      "top-5",
      "left-5",
      {
        "bg-gold": backgroundColor === "gold",
        "bg-red-700": backgroundColor === "red",
        "bg-[#e97254]": backgroundColor === "orange",
      },
      className
    )}
  >
    {children}
  </mark>
);
