import { COMMON_CLIENT_CONFIG } from "@projectluna/lib/config/common/client";
import { type Paths } from "@projectluna/lib/paths/types";
import { getTranslation } from "@projectluna/lib/saleor/intl";

import { type LinkProps } from "@/components/Link";
import { type MenuItemFragment } from "@/graphql/fragments/generated";

export const getLinkPath = ({
  paths,
  item,
}: {
  item: MenuItemFragment;
  paths: Paths;
}) => {
  if (item.category) {
    return paths.category.asPath({ slug: item.category.slug });
  }

  if (item.collection) {
    return paths.collection.asPath({ slug: item.collection.slug });
  }

  if (item.page) {
    return paths.page.asPath({ slug: item.page.slug });
  }

  return "#";
};

export const getLinkName = (item: MenuItemFragment) => {
  if (item.category) {
    return getTranslation("name", item.category);
  }

  if (item.collection) {
    return getTranslation("name", item.collection);
  }

  if (item.page) {
    return getTranslation("title", item.page);
  }

  return getTranslation("name", item);
};

export const getNavigationLinkProps = ({
  item,
  paths,
}: {
  item: MenuItemFragment;
  paths: Paths;
}): Pick<LinkProps, "href" | "target"> => {
  const host = new URL(COMMON_CLIENT_CONFIG.STOREFRONT_URL).host;

  if (item.url) {
    const isSameHost = new URL(item.url).host === host;

    return {
      href: item.url,
      target: isSameHost ? undefined : "_blank",
    };
  }

  return {
    href: getLinkPath({ paths, item }),
  };
};
