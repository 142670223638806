"use client";

import { useState } from "react";

import { createSafeContext } from "@projectluna/lib/react/context";

import { type WithChildren } from "@/lib/types";

const { useContext: useSideCartContext, Provider } = createSafeContext<{
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}>();

export const SideCartProvider = ({ children }: WithChildren) => {
  const [isOpen, setIsOpen] = useState(false);

  return <Provider value={{ setIsOpen, isOpen }}>{children}</Provider>;
};

export { useSideCartContext };
