"use client";

import { ShoppingBagIcon as HeroShoppingBagIcon } from "@heroicons/react/24/outline";

import { Icon } from "@/components/Icon";
import { type WithChildren } from "@/lib/types";
import { useSideCartContext } from "@/providers/SideCartProvider";

export const ShoppingBagIcon = ({ children }: WithChildren<false>) => {
  const { setIsOpen } = useSideCartContext();

  return (
    <Icon className="relative" onClick={() => setIsOpen(true)}>
      <HeroShoppingBagIcon />
      {children}
    </Icon>
  );
};
