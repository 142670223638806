"use client";

import { useScrollPosition } from "@/hooks/useScrollPosition";
import { type WithChildren } from "@/lib/types";

import { cn } from "@/styles/lib";

const NAV_COLLAPSE_OFFSET = 25;

export const Wrapper = ({ children }: WithChildren) => {
  const scrollPosition = useScrollPosition();

  const isScrolled = scrollPosition ?? 0 > NAV_COLLAPSE_OFFSET;

  return (
    <header
      className={cn(
        "sticky top-0 z-[11] w-full justify-center bg-white transition-shadow",
        {
          "shadow-md": isScrolled,
        }
      )}
    >
      {children}
    </header>
  );
};
