import debounce from "lodash/debounce";
import { useEffect, useState } from "react";

export const useScrollPosition = (wait = 150) => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const updatePosition = debounce(
      () => {
        /**
         * Vaul (which is used for drawers) adds negative top position fo current window scrollX to
         * the body upon open, so this is the most precise way to get real scroll position.
         */
        setScrollPosition(document?.body.getBoundingClientRect().y ?? 0);
      },
      wait,
      { trailing: true, leading: true }
    );

    window.addEventListener("scroll", updatePosition);
    updatePosition();

    return () => window.removeEventListener("scroll", updatePosition);
  }, []);

  return scrollPosition;
};
