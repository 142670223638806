import { useTranslations } from "next-intl";

import { Badge as BaseBadge } from "@/components/Badge";
import { CLIENT_CONFIG } from "@/config/client";

import { type InjectedProps } from "../types";

import { cn } from "@/styles/lib";

const POSITION_CLASSES = "left-5 top-5";
const SM_CLASSES = "left-2 top-2 text-xs";

export const Badge = (props: InjectedProps<false>) => {
  const t = useTranslations("product");
  const {
    product: { isAvailable, pricing, created },
    variant,
  } = props as InjectedProps<true>;
  const isSm = variant === "sm";

  if (!isAvailable) {
    return (
      <BaseBadge
        backgroundColor="red"
        className={cn(POSITION_CLASSES, { [SM_CLASSES]: isSm })}
      >
        {t("soldOut")}
      </BaseBadge>
    );
  }

  if (pricing?.onSale) {
    return (
      <BaseBadge className={cn(POSITION_CLASSES, { [SM_CLASSES]: isSm })}>
        {t("new")}
      </BaseBadge>
    );
  }

  const createdAt = new Date(created);
  const createdItWithThreshold = new Date(
    new Date(createdAt).setDate(
      createdAt.getDate() + CLIENT_CONFIG.NEW_PRODUCT_BADGE_DAYS
    )
  );
  const today = new Date();
  const inNewProduct = createdItWithThreshold > today;

  if (inNewProduct) {
    return (
      <BaseBadge
        backgroundColor="orange"
        className={cn(POSITION_CLASSES, { [SM_CLASSES]: isSm })}
      >
        {t("new")}
      </BaseBadge>
    );
  }

  return null;
};
