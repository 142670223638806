"use client";

import { usePathname, useSearchParams } from "next/navigation";
import { useEffect, useState } from "react";

export const useOnRouteChange = (
  callback: () => Promise<unknown> | unknown
) => {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const [prevRoute, setPrevRoute] = useState<string>("");

  useEffect(() => {
    const route = `${pathname}${searchParams}`;

    if (!prevRoute) {
      return setPrevRoute(route);
    }

    if (prevRoute !== route) {
      setPrevRoute(route);
      callback();
    }
  }, [pathname, searchParams, callback]);
};
