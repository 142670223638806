"use client";

import { XMarkIcon } from "@heroicons/react/24/outline";
import EditorJSHTML from "editorjs-html";
import { useCallback, useEffect, useRef, useState } from "react";

import { parseEditorJSData } from "@/lib/richText";

import { cn } from "@/styles/lib";

const PIXELS_PER_SECOND = 30;
const MIN_ITEMS = 10;

export const PromotionsBar = ({ items }: { items: string[] }) => {
  const tickerRef = useRef<HTMLDivElement>(null);

  const editorHtml = EditorJSHTML();
  const [animationDuration, setAnimationDuration] = useState(50);
  const [isVisible, setIsVisible] = useState(false);

  const setAnimationSpeed = useCallback(() => {
    if (tickerRef.current) {
      const totalWidth = tickerRef.current.scrollWidth;
      const uniqueContentWidth = totalWidth / 2; // Halve the width for duplicated content

      const newAnimationSpeed = uniqueContentWidth / PIXELS_PER_SECOND;

      setAnimationDuration(Math.round(newAnimationSpeed));
    }
  }, []);

  useEffect(() => {
    setAnimationSpeed();
    /**
     * Just for pleasant UX animation.
     */
    setIsVisible(true);
  }, []);

  return (
    <div
      className={cn(
        "relative flex h-12 w-0 min-w-full items-center overflow-x-hidden border-b border-b-gold-100 transition-all duration-700",
        {
          "h-0 min-h-0": !isVisible,
        }
      )}
    >
      <div
        className="absolute right-0 z-10 flex h-full w-14 cursor-pointer items-center bg-white pl-3"
        onClick={() => setIsVisible(false)}
      >
        <XMarkIcon className="relative h-6 w-6" />
      </div>
      <div
        className="flex flex-[1_1_100%] animate-ticker gap-52 hover:[animation-play-state:paused]"
        ref={tickerRef}
        style={{
          animationDuration: `${animationDuration}s`,
        }}
      >
        {cloneItems(items).map((item, i) => (
          <div className="flex overflow-hidden" key={i}>
            <div
              className="prose-sm whitespace-nowrap [&_a]:underline"
              dangerouslySetInnerHTML={{
                __html: editorHtml.parse(parseEditorJSData(item)),
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const cloneItems = (items: string[]) => {
  const itemsNum = items.length;

  if (itemsNum >= MIN_ITEMS) {
    return items;
  }

  return [...Array(MIN_ITEMS - itemsNum)].map((_, i) => items).flat();
};
