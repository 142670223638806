import { useEffect, useRef } from "react";

export function useWindowEvent<
  TType extends keyof WindowEventMap,
  Listener extends (ev: WindowEventMap[TType]) => any,
>(
  type: TType,
  listener: Listener,
  options?: boolean | AddEventListenerOptions
) {
  const listenerRef = useRef<Listener>(undefined);

  useEffect(() => {
    listenerRef.current = listener;
  }, [listener]);

  useEffect(() => {
    function handler(event: WindowEventMap[TType]) {
      listenerRef.current?.(event);
    }

    window.addEventListener(type, handler, options);
    return () => window.removeEventListener(type, handler, options);
  }, [type, options]);
}
