"use client";

import { XMarkIcon } from "@heroicons/react/24/solid";

import { Drawer } from "@/components/Drawer";
import { Icon } from "@/components/Icon";
import { ShoppingBag } from "@/components/ShoppingBag";
import { useOnRouteChange } from "@/hooks/useOnRouteChange";
import { type WithChildren } from "@/lib/types";
import { useSideCartContext } from "@/providers/SideCartProvider";

export const CartDrawer = ({ children }: WithChildren) => {
  const { isOpen, setIsOpen } = useSideCartContext();

  useOnRouteChange(() => {
    if (isOpen) {
      setIsOpen(false);
    }
  });

  return (
    <Drawer isOpen={isOpen} setIsOpen={setIsOpen} side="right">
      <div className="p-4">
        <ShoppingBag>
          <ShoppingBag.Header>
            <Icon onClick={() => setIsOpen(false)}>
              <XMarkIcon />
            </Icon>
          </ShoppingBag.Header>

          {children}
        </ShoppingBag>
      </div>
    </Drawer>
  );
};
