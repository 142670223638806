import { type QuantityAction } from "@/components/CartQuantityInput";
import {
  type CheckoutBaseFragment,
  type CheckoutFragment,
} from "@/graphql/fragments/generated";
import { isNotNil } from "@/lib/core";
import { type Maybe } from "@/lib/types";

export type WithCheckout = { checkout: Maybe<CheckoutBaseFragment> };

type LineDeleteInput = { lineId: string };

type LineChangeInput = {
  action: QuantityAction;
  lineId: string;
  quantity: number;
};

type LineAddInput = {
  quantity: number;
  variantId: string;
};

export const extractModifiedLine = ({
  lines,
  linesInput,
}: {
  lines: CheckoutFragment["lines"];
  linesInput: (LineAddInput | LineChangeInput | LineDeleteInput)[];
}) => {
  const linesAdded: CheckoutFragment["lines"] = [];
  const linesRemoved: CheckoutFragment["lines"] = [];

  lines?.forEach(checkoutLine => {
    const delatedLine = linesInput.find(line => {
      if ("lineId" in line && !("quantity" in line)) {
        return line.lineId === checkoutLine.id;
      }
    }) as LineDeleteInput | undefined;

    const updatedLine = linesInput.find(line => {
      if ("lineId" in line) {
        return line.lineId === checkoutLine.id;
      }
    }) as LineChangeInput | undefined;

    const addedLine = linesInput.find(line => {
      if ("variantId" in line) {
        return line.variantId === checkoutLine.variant.id;
      }
    }) as LineAddInput | undefined;

    if (addedLine) {
      /**
       * Add new line.
       */
      linesAdded.push({ ...checkoutLine, quantity: addedLine.quantity });
    } else if (delatedLine) {
      /**
       * Lines remove
       */
      linesRemoved.push({ ...checkoutLine });
    } else if (updatedLine) {
      /**
       * Lines change.
       */
      if (isNotNil(updatedLine.quantity)) {
        if (updatedLine.action === "change") {
          const isRemoveAction = checkoutLine.quantity > updatedLine.quantity;

          if (isRemoveAction) {
            linesRemoved.push({
              ...checkoutLine,
              quantity: checkoutLine.quantity - updatedLine.quantity,
            });
          } else {
            linesAdded.push({
              ...checkoutLine,
              quantity: updatedLine.quantity - checkoutLine.quantity,
            });
          }
        } else {
          /**
           * Increase/decrease.
           */
          const list =
            updatedLine.action === "decrease" ? linesRemoved : linesAdded;
          list.push({ ...checkoutLine, quantity: 1 });
        }
      }
    }
  });

  return { linesAdded, linesRemoved };
};

export const calculateLinesQuantity = (
  checkout: Maybe<CheckoutBaseFragment>
) =>
  checkout?.lines.reduce((sum: number, line) => sum + line.quantity, 0) ?? 0;
